<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="头像" prop="imgUrl">
        <oss-multi-upload v-model="form.imgUrl" :maxCount="imgMaxCount" type="img"></oss-multi-upload>
      </a-form-model-item>
      <a-col :md="8" :sm="24">
        <a-form-item label="性别" prop="gender">
          <a-select placeholder="请选择性别" style="width: 100%"  v-model="form.gender" allowClear>
            <a-select-option v-for="(item, index) in this.customDict.GenderEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAvatar, addAvatar, updateAvatar } from '@/api/config/avatar'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssMultiUpload
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        modifier: null,

        remark: null,
        gender: null,
        imgUrl: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        imgUrl: [
          { required: true, message: '头像不能为空', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '头像不能为空', trigger: 'blur' }
        ]

      },
      // 新增允许一次上传多张, 编辑只能编辑一个
      imgMaxCount: 1
    }
  },
  filters: {
  },
  created() {
  },
  computed: { ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        modifier: null,

        remark: null,

        imgUrl: null,

        gender: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加';
      this.imgMaxCount = 99
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2;
      this.imgMaxCount = 1;
      const id = row ? row.id : ids
      getAvatar(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAvatar(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAvatar(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
