import request from '@/utils/request'

// 查询随机用户头像列表
export function listAvatar(query) {
  return request({
    url: '/config/avatar/list',
    method: 'get',
    params: query
  })
}

// 查询随机用户头像详细
export function getAvatar(id) {
  return request({
    url: '/config/avatar/' + id,
    method: 'get'
  })
}

// 新增随机用户头像
export function addAvatar(data) {
  return request({
    url: '/config/avatar',
    method: 'post',
    data: data
  })
}

// 修改随机用户头像
export function updateAvatar(data) {
  return request({
    url: '/config/avatar',
    method: 'put',
    data: data
  })
}

// 删除随机用户头像
export function delAvatar(id) {
  return request({
    url: '/config/avatar/' + id,
    method: 'delete'
  })
}
